import Grid from '@material-ui/core/Grid'
import React, { useCallback } from 'react'
import { Button } from '../../common/Buttons'
import { ModalDialog } from '../../common/Dialogs'
import { useTrans } from '../../../services/i18n'
import { useAlertContext } from '../../../contexts/AlertContext'
import { getInitialValues, useScheme, roles } from './helpers'
import { useForm } from '../../../hooks/useForm'
import { Autocomplete } from '../../common/Inputs'
import { application } from '../../../services/application'
import { API } from '../../../constants'

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  enableReinitialize: true,
}

export default function FormUserStaff({
  quote,
  open,
  onClose,
  handleAction,
  fetchList,
  users,
}) {
  const { trans } = useTrans()
  const schema = useScheme()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const closeModal = () => {
    resetForm()
    onClose()
  }

  const onSubmit = useCallback(
    values => {
      if (typeof handleAction !== 'function') return
      setIsSend(true)
      Promise.resolve(
        handleAction({ id: quote.id, assignedUserId: values.user.userName })
      )
        .then(async res => {
          if (res.error && res.error.message) {
            errorAlert(res.error.message);
          } else {
            successAlert(res.message);
            const userEmail = values.user.email;
            if (userEmail) {
              try {
                await application.call(API.REQUESTED_QUOTES.QUOTE_ASSIGNMENT_NOTIFICATION, {
                  email: userEmail,
                  quote
                })
                successAlert('Email sent successfully');
              } catch (emailError) {
                errorAlert('Error sending email');
              }
            }
            closeModal()
            fetchList()
          }
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false))
    },
    [closeModal, handleAction, infoAlert, errorAlert, successAlert, quote]
  )

  const {
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    isValid,
    resetForm,
  } = useForm(schema, getInitialValues(quote), onSubmit, formOptions)

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={'Assign User Staff to Quote Request'}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={handleSubmit}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            options={users}
            label={trans('select-user')}
            placeholder={trans('required')}
            {...getFieldProps('user')}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  )
}
